// @flow
import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {KeycloakProvider} from '@react-keycloak/web'
import axios from 'axios'

import keycloak from '../../keycloak'
import {AppRouter} from '../../routes'
import {setUserData} from "../../store/actions";

const config: object = {
    onLoad: 'login-required',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIwYmE5YTU1Yi1iOWJjLTQ0MjgtYjE3Ny1iZGZjMmQ1OGI1NTIifQ.eyJleHAiOjAsImlhdCI6MTYwNzg5OTUxNiwianRpIjoiNmYzOTVhOWYtZDc5Ny00MThiLWI0ZGEtNjUyMGI0MjZlZmNiIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5zdGFkdG1pc3Npb24uZGV2L2F1dGgvcmVhbG1zL1FTIiwiYXVkIjoiaHR0cHM6Ly9sb2dpbi5zdGFkdG1pc3Npb24uZGV2L2F1dGgvcmVhbG1zL1FTIiwidHlwIjoiSW5pdGlhbEFjY2Vzc1Rva2VuIn0.nlOD2vhzfPfxFUCdeHNQQJ7tkLXoLJPDgZctm3xnaVE'
}

const KeycloakWrapper = () => {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const onKeycloakEvent = (event, error) => {
        if (event === 'onAuthSuccess') {
            keycloak.loadUserInfo().then(userInfo => {
                console.log('user')
                axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
                axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api'
                dispatch(setUserData(userInfo))
            }).catch(err => console.log(err))

            setInterval(() => {
                keycloak.updateToken(180).then((refreshed) => {
                    if (refreshed) {
                        axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
                        console.log('Token refreshed', refreshed)
                    }
                    else console.log('Token not refreshed', refreshed)
                }).catch(() => {
                    console.error('Failed to refresh token')
                    keycloak.logout()
                })
            }, 60000)

        } else if (event === 'onTokenExpired') {
            keycloak.logout()
        }
    }

    return (
        <KeycloakProvider keycloak={keycloak} initConfig={config} onEvent={onKeycloakEvent}>
            <AppRouter />
        </KeycloakProvider>
    )
}

export default KeycloakWrapper
