import React, {useEffect, useRef} from 'react'
import {Col, ConfigProvider, DatePicker, Divider, Form, Input, InputNumber, Row, Select} from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {Editor} from '@tinymce/tinymce-react'

import 'moment/locale/de'
import locale from 'antd/es/date-picker/locale/de_DE'
import Autocomplete  from 'react-google-autocomplete'

const { RangePicker } = DatePicker
const { Option } = Select
const { TextArea } = Input

interface FormElement {
    formElement: any,
    defaultValue?: any,
    field?: any,
    className?: string,
    autocomplete?: any
    onChange?: any
    editorRef?: any
}

moment.locale('de')



const FormElement = ({formElement, defaultValue, field, className, autocomplete, onChange, editorRef}: FormElement) => {

    const user = useSelector(state => state.user.user)

    if (['subtitle', 'title'].includes(formElement.type))
        return <h6 className={'text-uppercase font-weight-bold mt-4'}>{formElement.label}</h6>
    else if (formElement.type === 'divider')
        return <Divider />
    else
        return (
            <>

                <Form.Item
                    label={formElement.label}
                    name={(field || formElement.fieldKey) ? [(formElement?.fieldKey || field.name), formElement.name] : formElement.name}
                    fieldKey={[field?.fieldKey, formElement.name]}
                    rules={[{ required: formElement.required, message: 'Pflichtfeld!' }]}
                    initialValue={formElement.type === 'date' ? moment(defaultValue || moment().startOf('day')) : defaultValue?._id || defaultValue}
                    normalize={value => {
                        // if (formElement.type === 'textarea') return formElement.value
                        if (formElement.type === 'mapslist') return
                        if (formElement.type === 'date') return value !== null ? moment(value.startOf('day')) : value
                        if (formElement.type === 'textareaTMCE') return formElement.value
                        return value
                    }}
                    className={className}
                >
                    {(() => {
                        switch (formElement.type) {
                            case 'select':
                                return (
                                    <Select placeholder="Bitte auswählen">
                                        {
                                            formElement?.fields?.map((field, key) => <Option value={field._id || field.Code} key={formElement.name + key}>{field.label || field.name_kurz}</Option>)
                                        }
                                    </Select>
                                )
                            case 'range':
                                return <RangePicker locale={locale} format={'DD.MM.YYYY'} />
                            case 'userbereich':
                                return (
                                    <Select placeholder="Bitte auswählen">
                                        {
                                            user.bereich.map((field, key) => <Option value={field} key={field + key}>{field}</Option>)
                                        }
                                    </Select>
                                )
                            case 'checkbox':
                                return (
                                    <Select
                                        mode="tags"
                                        placeholder={formElement.label}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            formElement?.fields?.map((field) => <Option value={field._id || field.id}>{field.label || `${field.name}`}</Option>)
                                        }
                                    </Select>
                                )
                            case 'date':
                                return <DatePicker locale={locale} format={'DD.MM.YYYY'} />
                            case 'textarea':
                                return <TextArea value={formElement.value} onChange={onChange} />
                            case 'textareaTMCE':
                                return <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    apiKey={'cgurp8vodxcbclghg74z1b4chalceg53jcw2sqwz8ylkx5j9'}
                                    initialValue={defaultValue}
                                    plugins={[
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount hr'
                                    ]}
                                    toolbar={
                                        'hr | bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help'
                                    }
                                    init={{
                                        height: 300,
                                        menubar: false,
                                    }}
                                />
                            case 'number':
                                return <InputNumber min={0} decimalSeparator={','} />
                            case 'title':
                                return <h3>{formElement.label}</h3>
                            case 'subtitle':
                                return <h6 className={'text-uppercase font-weight-bold mt-4'}>{formElement.label}</h6>
                            case 'mapslist':
                                return <Autocomplete
                                    apiKey={'AIzaSyAq_78UQu_JVAmDMkZ8z9veoNCKTJz3u_Y'}
                                    className={'ant-input'}
                                    onPlaceSelected={(place, ref, autocompleteRef) => {
                                        console.log(place)
                                        autocomplete(place)
                                    }}
                                    options={{
                                        fields: ['address_components', 'geometry/location', 'place_id', 'formatted_address'],
                                        types: ['establishment', 'geocode'],
                                        componentRestrictions: { country: "de" },
                                    }}
                                />
                            default:
                                return <Input />
                        }
                    })()}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues[formElement.name] !== currentValues[formElement.name]}
                >
                    {({ getFieldValue }) =>
                        getFieldValue(formElement.name) === '605b91fd863a6834306c9464' ? (
                            <Form.Item
                                name={formElement.name + '_Andere'}
                                label="Andere"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
            </>
        )
}

export default FormElement
