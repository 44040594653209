import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Button, Card, Col, DatePicker, Divider, Form, List, Row, Space, Spin, Statistic, Table} from 'antd'
import { useDispatch } from 'react-redux'

import PageWrapper from '../components/wrapper/page'
import axios from 'axios'
import Title from '../components/title/title'
import FormElement from '../components/forms/FormElement'
import configuration from '../configuration'
import moment from 'moment'
import TableAuftraegeStatistik from '../components/tables/auftraegeStatistik'
import fileDownload from 'js-file-download'

const { RangePicker } = DatePicker

export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [form, setForm] = useState(null as any)
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState({pools: [] as any,
        km: 0,
        anrufe: 0,
        angetroffen: 0,
        angetroffenRolli: 0,
        gefahren: 0,
        gefahrenRolli: 0,
        angetroffen_f: 0,
        angetroffenRolli_f: 0,
        gefahren_f: 0,
        gefahrenRolli_f: 0,
        nahrung: 0,
        decke: 0,
        schlafsack: 0,
        kleidung: 0,
        gespraech: 0
    })
    const [times, setTimes] = useState({} as any)

    useEffect(() => {
        let formValues = configuration().forms.statistik

        axios.post('/form', formValues)
            .then(data => setForm(data.data))
            .catch(error => console.log(error))
    }, [])

    const onFinish = (values: any) => {
        setTimes(values)
        setLoading(true)

        axios.post('schicht/statistik', values)
            .then(data => {
                setItems(data.data)
                // console.log(data.data)
                setLoading(false)
            })
            .catch(error => console.log(error))
    }

    const downloadKlienten = () => {
        axios.post('/schicht/statistik/download', times, {responseType: 'blob'})
            .then(data => {
                // console.log(data)
                fileDownload(data.data, `exportAufträge.csv`)
            })
            .catch(error => console.log(error))
    }

    const downloadAnrufe = () => {
        axios.post('/schicht/statistik/anrufe/download', times, {responseType: 'blob'})
            .then(data => {
                // console.log(data)
                fileDownload(data.data, `exportAnrufe.csv`)
            })
            .catch(error => console.log(error))
    }

    const downloadSchichten = () => {
        axios.post('/schicht/statistik/schichten/download', times, {responseType: 'blob'})
            .then(data => {
                // console.log(data)
                fileDownload(data.data, `exportSchichten.csv`)
            })
            .catch(error => console.log(error))
    }

    const columns = [
        {
            title: 'Bus',
            width: '20%',
            render: item => <span>{item.team?.name} / {item.fahrzeug?.link?.name}</span>
        },
        {
            title: 'Start',
            dataIndex: 'created_at',
            width: '10%',
            render: start => <span>{moment(start).format('HH:mm')}</span>
        },
        {
            title: 'Ende',
            dataIndex: 'end',
            width: '10%',
            render: end => <span>{moment(end).format('HH:mm')}</span>
        },
        {
            title: 'Fahrende',
            dataIndex: 'fahrende',
            width: '40%',
            render: text => <span>{text.fahrer.name} / {text.beifahrer.name} / {text.mitfahrer.name}</span>
        },
        {
            title: 'Kilometer',
            dataIndex: 'fahrzeug',
            render: text => <span>{text?.kmEnd - text?.kmStart}</span>
        },
        {
            title: 'übernommene Aufträge',
            dataIndex: 'auftraege'
        }
    ]

    return (
        <PageWrapper title={'Statistik'}>
            <Form layout={'vertical'} onFinish={onFinish} className={'py-3 mb-3'}>
                <Row gutter={24} >
                    {
                        form && form.map((el: any, i: number) => {
                            if (el.type === 'title') {
                                return <Col span={24} key={i}><Title text={el.label} /></Col>
                            } else {
                                return (
                                    <Col key={i} span={4}>
                                        <FormElement className={'m-0'} formElement={el} />
                                    </Col>
                                )
                            }
                        })
                    }
                    <Col span={16} className={'d-flex align-items-end'}>
                        <Button type="primary" htmlType={'submit'}>Suchen</Button>
                        <Button className={'ml-2'} onClick={() => downloadKlienten()}>Download Aufträge</Button>
                        <Button className={'ml-2'} onClick={() => downloadAnrufe()}>Download Anrufe</Button>
                        <Button className={'ml-2'} onClick={() => downloadSchichten()}>Download Schichten</Button>
                    </Col>
                </Row>
            </Form>



            <div className="site-statistic-demo-card mb-5">
                <Spin tip="Loading..." spinning={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Angetroffen / Kontakte (Männlich)"
                                    value={`
                                        ${items?.angetroffen + items?.angetroffenRolli}, davon ${items?.angetroffenRolli} R
                                    `}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Angetroffen / Kontakte (Weiblich)"
                                    value={`
                                        ${items?.angetroffen_f + items?.angetroffenRolli_f}, davon ${items?.angetroffenRolli_f} R
                                    `}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Gefahren (Männlich)"
                                    value={`${items?.gefahren + items?.gefahrenRolli}, davon ${items?.gefahrenRolli} R`}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Gefahren (Weiblich)"
                                    value={`${items?.gefahren_f + items?.gefahrenRolli_f}, davon ${items?.gefahrenRolli_f} R`}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Kilometer"
                                    value={items?.km}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Anrufe"
                                    value={items?.anrufe}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Ausgabe - Schlafsack"
                                    value={items?.schlafsack}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Ausgabe - Nahrung"
                                    value={items?.nahrung}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Ausgabe - Decke"
                                    value={items?.decke}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Ausgabe - Kleidung"
                                    value={items?.kleidung}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title="Gespräche"
                                    value={items?.gespraech}
                                    groupSeparator={' '}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>

            {
                items.pools.map((data: any) => (
                    <>
                        <Row>
                            <Col span={24}>
                                <h4 className={'font-weight-bold'}>{data.pool} - Anrufe: {data.anrufe} / Abgeschlossene Aufträge: {data.auftraege.filter((data: any) => data?.gemeldet?.anrufer).length} / Offene Aufträge: {data.auftraege.filter((data: any) => !data?.close).length}</h4>
                            </Col>
                            <Col span={24}>
                                <Table columns={columns} dataSource={data.schichten} pagination={false} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className={'mb-5'}>
                                <TableAuftraegeStatistik data={data.auftraege} />
                            </Col>
                        </Row>
                    </>
                ))
            }
        </PageWrapper>
    )
}
