const configuration = () => ({
    routes: [
        {
            title: 'Aufträge',
            url: '/auftrag/pool',
            active: ['admin']
        },
        {
            title: 'Statistik',
            url: '/statistik/auswertung'
        },
        {
            title: 'Einstellungen',
            url: '/einstellungen'
        }
    ],
    menus: {},
    tables: {},
    forms: {
        anrufer: [
            {
                name: 'anrufer',
                link: 'anrufer'
            }
        ],
        auftrag_erfassen: {
            standard: [
                {
                    name: 'anrufer',
                    label: 'Wer hat angerufen?',
                    type: 'select',
                    link: 'anrufer',
                    required: true
                },
                {
                    name: 'umgebung',
                    label: 'Geschützte Umgebung?',
                    type: 'select',
                    link: 'umgebung',
                    width: 6
                },
                {
                    name: 'personen',
                    label: 'Personen',
                    type: 'number',
                    width: 3
                },
                {
                    name: 'prio',
                    label: 'Priorität',
                    link: 'prio',
                    type: 'select',
                    width: 7,
                    required: true
                },
                {
                    name: 'rolli',
                    label: 'Rollstuhl',
                    type: 'select',
                    link: 'rolli',
                    width: 4,
                    required: true
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    type: 'mapslist',
                    width: 8
                },
                {
                    name: 'pluscode',
                    label: 'Plus Code',
                    width: 4
                },
                {
                    name: 'ansprechpartner',
                    label: 'Ansprechpartner'
                },
                {
                    name: 'beschreibung',
                    label: 'Genauere Beschreibung',
                    type: 'textarea',
                    width: 24
                }
            ]
        },
        statistik: [
            {
                name: 'zeitraum',
                label: 'Zeitraum auswählen',
                type: 'range',
                required: true
            },
            {
                name: 'filter',
                label: 'Filter',
                link: 'tbl_User',
                type: 'checkbox'
            }
        ],
        neuesTeam: [
            {
                name: 'name',
                label: 'Name'
            },
            {
                name: 'telefon',
                label: 'Telefonnummer'
            },
            {
                name: 'bemerkung',
                label: 'Bemerkungen'
            }
        ],
        neuesFahrzeug: [
            {
                name: 'name',
                label: 'Name'
            },
            {
                name: 'kennzeichen',
                label: 'Kennzeichen'
            },
            {
                name: 'trackerID',
                label: 'Tracker ID'
            },
            {
                name: 'trackerKennung',
                label: 'IMEI'
            },
        ]
    }
})


export default configuration
