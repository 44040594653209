import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
    "realm": process.env.REACT_APP_KEYCLOAK_REALM || "ZaZ",
    "url": "https://login.app.berliner-stadtmission.de/auth/",
    "clientId": "react-frontend"
})

export default keycloak
